import React, { useEffect, useState } from "react";
import { Bars } from  'react-loader-spinner'
import quanderLogo from "../assets/quanderLogo.png"
import { useNavigate } from "react-router-dom";
import { server, useAuth } from '../useAuth'
import'./home.css'
import axios from 'axios'
import ConsentText from '../assets/consentingText'
import ParentConsentText from '../assets/parentConsentingText'

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
};

const title = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const ConsentPage = () => {
  let auth = useAuth();
  const navigate = useNavigate();
  const [isUSA, setIsUSA] = useState('');
  const [isClass, setIsClass] = useState('');
  const [classConsent, setClassConsent] = useState('');
  const [age, setAge] = useState('');
  const [consented, setConsented] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [codeRequested, setCodeRequested] = useState(false);
  const [researchCode, setResearchCode] = useState('');
  // const [checked, setChecked] = useState(false);
  // const [clicked, setClicked] = useState(false);
  // const [researchCode, setResearchCode] = useState('');
  const handleConsent = () => {
    setConsented(!consented);
  };

  const handleSubmit = () =>{
    setSubmitted(true);
    if(consented){
      setCodeRequested(true);
      auth.genCode({'classConsent':classConsent,'livesInUSA':isUSA, 'ageGroup': age}).then((e)=>{setResearchCode(e.data);})
      // TODO server call
    } else if (classConsent=='yes'){
      // setCodeRequested(true);
      // auth.genCode({'classConsent':'yes', 'livesInUSA':'ClassConsent', 'ageGroup': 'ClassConsent'}).then((e)=>{setResearchCode(e.data);})
    }
  }

  const handleUSARadio = (e) => {
    setIsUSA(e.currentTarget.value);
    // setNationSelected(true);
  }

  const handleClassRadio = (e) => {
    setIsClass(e.currentTarget.value);
    // setNationSelected(true);
  }

  const handleClassConsent = (e) => {
    if(classConsent == "yes"){
      setClassConsent("no")
    }else{
      setClassConsent("yes")
      setConsented(true)
    }
    // setClassConsent(e.currentTarget.value);
    // setNationSelected(true);
  }

  const handleAge = (e) => {
    setAge(e.currentTarget.value);
    // setNationSelected(true);
  }

  const loadingIcon = 
          <>
          <h1 style={{color:"white"}}> Generating Research Code </h1>
          <div style={{width:"300px", margin:"0 auto", color:"white"}}>
          <Bars color="#8162a7" width={300} height={300}/></div>
          </>
  const codeDisplay = <>
    <h1 style={{color:"white"}}> Here's your research code! Be sure to write it down </h1>
    <h1 style={{color:"white", fontSize:"64px"}}>{researchCode}</h1>
    <h2>All letters are uppercase.</h2>
      <button onClick={()=>navigate('/')}>
        Launch game!
      </button>
  </>
  const classForm = <>
    <h2>Are you completing Quander as part of an in-class activity? </h2>
    <label>
        <input
          type="radio"
          value="yes"
          checked={isClass=="yes"}
          onChange={handleClassRadio}

        />
        Yes
    </label>
    <label>
        <input
          type="radio"
          value="no"
          checked={isClass=="no"}
          onChange={handleClassRadio}
        />
        No
    </label>
  </>
  const classConsentForm = <>
    {/*<h2>Have you completed the consent form given to you by your teacher? </h2>*/}
    <br/>
    <label>
        <input
          type="checkbox"
          // value="yes"
          checked={classConsent=="yes"}
          onChange={handleClassConsent}

        />
        I am playing these games as part of a school group or other youth program and my parent or guardian has received the opt out form.
    </label>
    
  </>

  

  
  const nationalityForm = <>
    <h2>Do you live in the United States of America? </h2>
    <label>
        <input
          type="radio"
          value="yes"
          checked={isUSA=="yes"}
          onChange={handleUSARadio}

        />
        Yes
    </label>
    <label>
        <input
          type="radio"
          value="no"
          checked={isUSA=="no"}
          onChange={handleUSARadio}
        />
        No
    </label>
  </>


  const ageForm = <>
    <h2>How old are you? </h2>
    <label>
        <input
          type="radio"
          value="under 8"
          checked={age=="under 8"}
          onChange={handleAge}

        />
        Under 8 years old<br/>
    </label>
    <label>
        <input
          type="radio"
          value="8-10"
          checked={age=="8-10"}
          onChange={handleAge}
        />
        8-10 years old<br/>
    </label>
    <label>
        <input
          type="radio"
          value="11-13"
          checked={age=="11-13"}
          onChange={handleAge}
        />
        11-13 years old<br/>
    </label>
    <label>
        <input
          type="radio"
          value="13-18"
          checked={age=="13-18"}
          onChange={handleAge}
        />
        13-18 years old<br/>
    </label>
    <label>
        <input
          type="radio"
          value="over 18"
          checked={age=="over 18"}
          onChange={handleAge}
        />
        Over 18 years old
    </label>
  </>


  const consentForm = <>
      <h2>
        {age == 'over 18' ? 'Please read the consent form below' : 'You will need a parent or guardian’s permission to play. Please ask an adult to provide permission'}
      </h2>
        <div style={{backgroundColor:"white", width:"80vw", 
          margin:"auto", maxWidth:"1300px", color:"black",
          fontSize:"14px", maxHeight:"50vh", overflow:"scroll", textAlign:"left",
          fontFamily:"Times"}}>
          {age == 'over 18' ? <ConsentText/> : <ParentConsentText/>}
        </div>
        {submitted ? <p style={{color:"red"}}> You must consent before requesting a research code</p>: []}
      <label>
        <input
          type="checkbox"
          checked={consented}
          onChange={handleConsent}
        />
        I consent
      </label>
      <br/>
      <br/>
      <button onClick={handleSubmit}>
        Get Research Code
      </button>
    </>

  const codeGenerationForm = <div style={{paddingTop:"10vh", color:"white", textAlign:"center"}}>
        <div style={{width:"80vw", 
          margin:"auto", maxWidth:"1300px",
          fontSize:"14px", maxHeight:"50vh", overflow:"scroll", textAlign:"center"}}>
          {researchCode == '' ? loadingIcon : codeDisplay}
        </div>
  </div>

  var output = []
  // output.push(classForm)
    output.push(nationalityForm)
    if(isUSA == 'yes'){
      output.push(ageForm)

      if(age == 'under 8'){
        output.push(<h2>Thank you for your interest. However, at this time, you are not old enough for this study</h2>)
      }else if(age != ''){
        output.push(classForm)
        if (isClass=='yes'){
          output.push(classConsentForm)
          if(classConsent == 'yes'){
            output.push(<>
              <br/>
              <br/>
              <button onClick={handleSubmit}>
                Get Research Code
              </button>
            </>)
          }
        } else if (isClass == 'no'){
          output.push(consentForm)
        }
        // output.push(consentForm)
      }

    } else if(isUSA == 'no'){
      output.push(<h2> Thank you for your interest. However, at this time, participating in this study is limited to people in the USA</h2>)
    }
  // else if (isClass=='yes'){
  //   output.push(classConsentForm)
  //   if(classConsent == 'yes'){
  //     output.push(<>
  //       <br/>
  //       <br/>
  //       <button onClick={handleSubmit}>
  //         Get Research Code
  //       </button>
  //     </>)
  //   }
  //   else if (classConsent == 'no'){
  //     output.push(<h2> Please complete your teacher's consent form in order to recieve a research code.</h2>)
  //   }
  // }
  if((consented && submitted && codeRequested) || (classConsent=='yes' && submitted && codeRequested)){
    return codeGenerationForm
  }
  return <div style={{paddingTop:"10vh", color:"white", textAlign:"center"}}>
    <h1>Welcome to Quander</h1>
            {output}
          </div>
};

export default ConsentPage;
