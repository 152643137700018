import { Link, Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { server, ProvideAuth, RequireAuth, useAuth } from "./useAuth"
import ConsentPage from "./pages/consent"
import HomePage from "./pages/home"
import Quantime from "./pages/quantime"
import './App.css';
import quanderLogo from "./assets/QuanderLogoVectorized.svg"


import axios from 'axios'

const Pricing = () => <h1>{console.log(useAuth())}Pricing (Public)</h1>;


const Settings = () => <h1>Settings (Private)</h1>;


// const Login = () => <h1>TODO</h1>

function Nav(props) {
  const location = useLocation();
  console.log(location)

  let isAuthenticated = useAuth();
  // console.log(["Checking token in Nav", isAuthenticated.parseJwt(isAuthenticated.token.data).sub])

  function handleSignOut(event) {
    event.preventDefault();

    // return axios.post('http://backend-env.eba-imqttpbj.us-east-1.elasticbeanstalk.com/hello/', {foo:'bar'}).then((e)=> {console.log(e)})

    return isAuthenticated.signout();
  }

  let makeNav = (path, name) => (path==location.pathname ? <Link className='active' to={path}>{name}</Link> : <Link to={path}>{name}</Link>)
  let makeNavRight = (path, name) => (path==location.pathname ? <Link className='right active' to={path}>{name}</Link> : <Link className='right' to={path}>{name}</Link>)
  let links = [
                makeNav('/', 'Home'),
                makeNav('/Consent', 'Consent'),
                makeNav('/Lite', 'Lite'),
                // makeNav('/Qupcakes', 'Qupcakes'),
                // makeNav('/Circuits', 'Circuits'),
                // makeNav('/Twintanglement', 'TwinTanglement'),
                // makeNavRight('/login', 'Log In')
              ]
  if(isAuthenticated && isAuthenticated.token && isAuthenticated.token.data && isAuthenticated.token.data !== "GUEST"){
    links.pop()
    links.push((<a className='right' onClick={handleSignOut}> Sign Out </a>))
  }
  return (
    <div className="topnav">
      <nav>
        {links.map((link, i) => {
          return <div key={i}> {link} </div>
        })}
      </nav>
    </div>
  );
}

export default class App extends React.Component{
  render() {

  // <Route path="/" element={<Circuits />} /> 
  return (
    <ProvideAuth>
      <BrowserRouter>
        <img src={quanderLogo} style={{width:"max(20vw, 200px)", position:"absolute", 
            top:'0px', left:"50%", marginLeft:"min(-10vw, -100px)"}}/>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage/> } />
          <Route path="/Lite" element={<Quantime/> } />
          <Route path="/Consent" element={<ConsentPage />} />
        </Routes>
      </BrowserRouter>
      </ProvideAuth>
  );
}
}