import React, { useEffect, useState, useRef } from "react";
import { Bars } from  'react-loader-spinner'
import quanderLogo from "../assets/quanderLogo.png"
import { Link } from "react-router-dom";
import { server, useAuth } from '../useAuth'
import'./home.css'
import axios from 'axios'
import ConsentText from '../assets/consentingText'
import ParentConsentText from '../assets/parentConsentingText'

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
};

const title = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};



const HomePage = () => {

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  let height = windowHeight.current*.7
  let width = (3840/2160)*height
  return <div style={{paddingTop:"10vh", color:"white", textAlign:"center", height:"80vh"}}>

      <br/>
      <iframe allowFullScreen src="https://d1e5n3798wiil6.cloudfront.net" height={`${height}px`} width={`${width}px`}>
      </iframe> 
      <br/>
      Need a research code? Click  <Link to="/Consent" style={{color:"white"}}> here</Link>  
          </div>
};

export default HomePage;
