import React, { useState, useEffect, useContext, createContext } from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios'
import jwt from "jwt-decode";
//Adapted from https://usehooks.com/useAuth/

const authContext = createContext();
export const server = 'https://backend-quantime.link/'
// export const server = 'http://quanderbanckend-env.eba-smu7b6w6.us-east-2.elasticbeanstalk.com'
// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth =  useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};


function useProvideAuth() {

	const genCode = (data) => {
		// return axios.post(server+'login', credentials).then((token) => {token.data == '' ? setToken(token); localStorage.setItem('jwt-token',JSON.stringify(token)); dest() : console.log('Wrong Pass')}, (e)=> {console.log(e)})
		return axios.post(server+'/generate_research_code', data)
	}

	  return {
    genCode,
  };
}