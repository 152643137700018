import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { server, useAuth } from '../useAuth'
import axios from 'axios'

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
};

const title = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const ConsentText = ()=>{

  return (
    <>
    <p>Protocol Number: 69-22-0742</p>
    <p>Approved by the UCSB Human Subjects Committee for use thru: 12/12/2032</p>
    <h2> SUMMARY OF KEY INFORMATION:</h2>
    <p>
      We have created a set of five games related to quantum computing and are testing them to understand (1) How well can people play online games with game mechanics corresponding to
      quantum mechanics properties? and (2) How do people respond and engage with the quantum games?. In other words, we want to know if they are too easy or too difficult and whether or not they are fun, interesting, and educational for players. If you choose to participate, you will play the Quander games and we will collect some data through the game. You will be assigned a unique login code and asked some questions about yourself including your age and what country you live in. While you are playing the game, we will collect information about which games you play, what you do in the game world, and what levels of each game you finish. No identifiable data will be collected. We will not know who you are or where you are.
    </p>
    <h2> PURPOSE:</h2>
    <p>
      You are being asked to participate in a research study. The purpose of the study is to understand (1) How well can people play online games with game mechanics corresponding to
      quantum mechanics properties? and (2) How do people respond and engage with the quantum games?. In other words, we want to know if they are too easy or too difficult and whether or not they are fun, interesting, and educational for players.
    </p>

    <h2> 
      PROCEDURES:
    </h2>
    <p>
      If you decide to participate, you will play the Quander games and we will collect some data through the game. You will be assigned a unique login code and asked some questions about yourself including your age and what country you live in. While you are playing the game, we will collect information about which games you play, what you do in the game world, and what levels of each game you finish. No identifiable data will be collected. We will not know who you are or where you are.
    </p>
    <h2>RISKS:</h2>
    <p> Playing these games is completely optional. It is unlikely the participants will encounter risks.</p>
    <h2>BENEFITS</h2>
    <p>From this research, we will learn about how people play these games and be able to improve them for future players.</p>
    <h2>CONFIDENTIALITY</h2>
    <p> The data we collect will not be linked to your identity in any way. We may use or share this unidentifiable data in future research studies yet to be determined.</p>
    <br/>
    <h2>QUESTIONS:</h2>
    <p>If you have any questions about this research project or if you have any questions or concerns about your rights and treatment as a research subject, please contact:</p>
    <p>Danielle Harlow, dharlow@education.ucsb.edu, 805 893-8139</p>
    <p>Diana Franklin, dmfranklin@uchicago.edu</p>
    <p>If you have any questions regarding your rights and participation as a research subject, please contact the Human Subjects Committee at (805) 893-3807 or hsc@research.ucsb.edu. Or write to the University of California, Human Subjects Committee, Office of Research, Santa Barbara, CA 93106-2050
    </p>
    </>
  )
}
export default ConsentText;
